<script setup lang="ts" name="XDropdown">
import { nextTick, ref, Teleport, useTemplateRef, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { createPopper } from '@popperjs/core';

const {
  teleport = true,
  parentRef,
  placement = 'bottom-start',
} = defineProps<{
  teleport?: boolean;
  offset?: [number, number];
  parentRef?: HTMLElement;
  placement?: string;
}>();

const emit = defineEmits(['close', 'open', 'clickOutside']);

const isOpen = ref(false);
const targetRef = useTemplateRef('targetRef');
const contentRef = useTemplateRef('contentRef');
const triggerRef = useTemplateRef('triggerRef');
const popper = ref(null);

onClickOutside(
  contentRef,
  () => {
    isOpen.value = false;
    emit('clickOutside');
  },
  { ignore: [targetRef] },
);

const close = () => {
  if (!isOpen.value) return;

  isOpen.value = false;
  emit('close');
};

const open = () => {
  if (isOpen.value) return;

  isOpen.value = true;
  emit('open');
};

const toggle = () => (isOpen.value ? close() : open());

const updatePopper = () => {
  if (!contentRef.value) return;

  const positioningRef = parentRef || triggerRef.value;

  popper.value = createPopper(positioningRef, contentRef.value, {
    placement: placement,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
      // The popper element width should be at least as large as the reference element width
      // https://github.com/floating-ui/floating-ui/issues/794
      {
        name: 'sameWidth',
        enabled: true,
        fn: ({ state }) => {
          state.styles.popper.minWidth = `${state.rects.reference.width}px`;
        },
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
  });
};

watch(
  () => isOpen.value,
  (opened) => {
    if (!opened) return;

    nextTick(() => {
      updatePopper();
    });
  },
  {
    immediate: true,
  },
);

defineExpose({
  open,
  close,
});
</script>

<template>
  <div ref="targetRef" class="relative">
    <div ref="triggerRef">
      <slot
        name="trigger"
        :toggle="toggle"
        :is-open="isOpen"
        :open="open"
        :close="close"
      />
    </div>
    <Teleport v-if="teleport" to="#dropdown" :disabled="!isOpen">
      <div
        v-if="isOpen"
        ref="contentRef"
        class="z-50 overflow-hidden rounded bg-white shadow-md"
      >
        <slot :close="close" :open="open" :is-open="isOpen" />
      </div>
    </Teleport>
    <template v-else>
      <div
        v-if="isOpen"
        ref="contentRef"
        class="z-50 overflow-hidden rounded bg-white shadow-md"
      >
        <slot :close="close" :open="open" :is-open="isOpen" />
      </div>
    </template>
  </div>
</template>
