import { onMounted, onUnmounted, ref, type ShallowRef } from 'vue';

export const useTabFocusEvent = (
  searchField: Readonly<ShallowRef<HTMLInputElement | null>>,
) => {
  const tabKeyup = ref(false);

  const onKeyup = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      tabKeyup.value = true;
    }
  };

  const onKeyDown = () => {
    tabKeyup.value = false;
  };

  onMounted(() => {
    searchField.value?.addEventListener('keyup', onKeyup);

    searchField.value?.addEventListener('keydown', onKeyDown);
    searchField.value?.addEventListener('blur', onKeyDown);
    searchField.value?.addEventListener('mousedown', onKeyDown);
  });

  onUnmounted(() => {
    searchField.value?.removeEventListener('keyup', onKeyup);

    searchField.value?.removeEventListener('keydown', onKeyDown);
    searchField.value?.removeEventListener('blur', onKeyDown);
    searchField.value?.removeEventListener('mousedown', onKeyDown);
  });

  return {
    tabKeyup,
  };
};
