<script setup lang="ts" name="VesselMap">
import { useTemplateRef, watch } from 'vue';
import { map, tileLayer } from 'leaflet';

import { LEAFLET_TILE_LAYER_OPTIONS } from '~/constants';

import { useLiveTracker } from '~/features/useVesselVoyage';

import BaseAlert from '~/components/BaseAlert.vue';
import Icon from '~/components/Icon.vue';

import type { IMapWithFullscreen, IVesselVoyageData } from '~/types';

const { mapData, serviceState = {} } = defineProps<{
  mapData?: IVesselVoyageData;
  serviceState: Record<string, unknown>;
}>();

const FULLSCREEN_MIN_ZOOM = 3;
const MINIMAP_MIN_ZOOM = 1;
const MAP_MAX_ZOOM = 7;

const containerRef = useTemplateRef<HTMLDivElement | null>('containerRef');

const updateMap = (data: IVesselVoyageData, container: HTMLDivElement) => {
  const mapObject: IMapWithFullscreen = map(container, {
    maxZoom: MAP_MAX_ZOOM,
    minZoom: MINIMAP_MIN_ZOOM,
    maxBounds: [
      [-270, -360],
      [270, 360],
    ],
  });

  const { fullScreen, featureGroup, geoJsonLayer } = useLiveTracker(data);

  tileLayer(
    import.meta.env.VITE_LEAFLET_TILE_LAYER,
    LEAFLET_TILE_LAYER_OPTIONS,
  ).addTo(mapObject);

  featureGroup.addTo(mapObject);
  fullScreen.addTo(mapObject);

  if (geoJsonLayer) geoJsonLayer.addTo(mapObject);

  mapObject.fitBounds(featureGroup.getBounds());

  mapObject.on('fullscreenchange', () => {
    if (mapObject.isFullscreen?.()) {
      mapObject.setMinZoom(FULLSCREEN_MIN_ZOOM);
      mapObject.setZoom(FULLSCREEN_MIN_ZOOM);
    } else {
      mapObject.setMinZoom(MINIMAP_MIN_ZOOM);
      mapObject.setZoom(MINIMAP_MIN_ZOOM);
    }

    mapObject.fitBounds(featureGroup.getBounds());
  });
};

watch(
  () => [mapData, containerRef.value],
  () => {
    if (!mapData || !containerRef.value) return;
    updateMap(mapData, containerRef.value);
  },
  {
    deep: true,
    immediate: true,
  },
);
</script>

<template>
  <div
    v-if="!serviceState.stateIsIdle"
    class="relative h-full bg-charcoal-1"
    :class="{
      'animate-pulse': serviceState.stateIsLoading,
    }"
  >
    <img
      src="/images/leaflet/map.svg"
      alt=""
      class="mx-auto h-full w-full object-scale-down object-center"
    />
    <div
      v-if="serviceState.stateIsError"
      data-test="error"
      class="absolute left-0 top-0 flex h-full w-full items-center justify-center"
    >
      <div class="flex flex-col items-center rounded p-4 text-charcoal-6">
        <Icon icon="mdi:map-marker-remove-outline" size="3xl" />
        <p class="text-center font-bold">Sorry. It’s not you, it’s us...</p>
        <p class="text-center">We are doing our best to resolve the issue.</p>
      </div>
    </div>

    <BaseAlert
      v-if="serviceState.stateIsNoData"
      type="info"
      data-test="no-data"
      class="absolute bottom-0 w-full"
    >
      Sorry, no data available...
    </BaseAlert>
  </div>

  <div v-else data-test="idle" style="height: 100%">
    <div ref="containerRef" data-test="vessel-map" style="height: 100%"></div>
  </div>
</template>
