<script setup lang="ts" name="DataTableTextAreaField">
import { computed, onMounted, ref, useTemplateRef } from 'vue';
import { onClickOutside } from '@vueuse/core';

const { value } = defineProps<{
  value: string | null;
}>();

const emit = defineEmits<{
  update: [val: string | null];
  keyDown: [event: KeyboardEvent];
  moveRightOrTab: [];
  resetEditMode: [];
  onEnter: [];
}>();

const inputValue = ref(value);
const inputRef = useTemplateRef<HTMLInputElement>('inputRef');

const valueChanged = computed(() => inputValue.value !== value);

onClickOutside(inputRef, () => {
  if (valueChanged.value) {
    emit('update', inputValue.value);
  }

  emit('resetEditMode');
});

const onKeyDown = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (event.key === 'Escape') {
    emit('resetEditMode');
    return;
  }

  if (event.key === 'Tab' || event.key === 'Enter') {
    if (valueChanged.value) {
      emit('update', inputValue.value);
    }

    if (event.key === 'Tab') {
      emit('moveRightOrTab');
    } else {
      emit('onEnter');
    }

    event.preventDefault();
  }
};

onMounted(() => {
  inputRef.value?.focus();
  inputRef.value?.select();
});
</script>

<template>
  <div class="h-6 w-full">
    <textarea
      class="h-9 w-full resize-none overflow-y-hidden bg-transparent outline-none"
      ref="inputRef"
      data-testid="text-area-field"
      v-model="inputValue"
      rows="1"
      wrap="off"
      @keydown="onKeyDown"
      @dblclick.stop
    />
  </div>
</template>
