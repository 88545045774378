<script lang="ts" setup name="FieldTextarea">
import { computed, useTemplateRef } from 'vue';
import { useVModel } from '@vueuse/core';

import { useTabFocusEvent } from '~/features/useTabFocusEvent';

import FieldBaseErrors from '~/components/FieldBaseErrors.vue';

const props = defineProps<{
  modelValue?: string;
  field: {
    name?: string;
    rows?: number;
    placeholder?: string;
    disabled?: boolean;
  };
  errors?: string[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: string): void;
  (e: 'change'): void;
  (e: 'blur'): void;
  (e: 'enter'): void;
}>();

const inputValue = useVModel(props, 'modelValue', emit);
const allErrors = computed(() => props.errors || []);

const inputRef = useTemplateRef<HTMLInputElement>('inputRef');
const { tabKeyup } = useTabFocusEvent(inputRef);

const rows = computed(() => props.field.rows || 10);
const placeholder = computed(
  () => props.field.placeholder || 'Type your message...',
);
</script>

<template>
  <div
    :data-test="`${field.name}-wrapper`"
    :class="{ 'flex w-full flex-col': $slots.actions }"
  >
    <textarea
      v-model="inputValue"
      class="max-h-44 w-full resize-none border bg-charcoal-0 p-4 text-base leading-4 text-charcoal-9 outline-none placeholder:text-charcoal-5 disabled:text-charcoal-4 disabled:placeholder:text-charcoal-4"
      :class="{
        'cursor-not-allowed border-charcoal-2': field.disabled,
        'border-charcoal-5 hover:border-charcoal-6':
          !field.disabled && !errors?.length,
        'ring ring-inset ring-ocean-3 focus:border-0': tabKeyup,
        'ring-inset focus:border-0 focus:ring-2 focus:ring-primary-6':
          !tabKeyup,
        'border-primary-6': !!inputValue && !field.disabled && !errors?.length,
        'border-danger-6': !!errors && errors.length > 0,
        'rounded-t-lg': $slots.actions,
        'rounded-lg': !$slots.actions,
      }"
      ref="inputRef"
      :rows="rows"
      :disabled="field.disabled"
      :placeholder="placeholder"
      @keydown.enter.prevent="emit('enter')"
      @change="emit('change')"
      @blur="emit('blur')"
    />
    <slot name="actions" />
    <FieldBaseErrors
      v-if="allErrors.length > 0"
      class="mt-1"
      :errors="allErrors"
      :data-test="`${field.name}-error`"
    />
  </div>
</template>
