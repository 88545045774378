import type { RouteRecordRaw } from 'vue-router';
import axios from 'axios';

import { DEV_FEATURE, REGISTRATION_STATUS } from '~/constants';
import { useOrganisationsStore, useProfileStore } from '~/store';

import { makePrivateRoute, makePublicRoute } from '~/features/useRouteMeta';

import Service from '~/services/Service';

import type {
  IAuthInviteResponse,
  IRegistrationBusinessType,
  IRegistrationConfirmEmail,
  RegistrationStatus,
} from '~/types';

const AuthProfile = () => import('~/pages/AuthProfile.vue');
const AuthAcceptInvitation = () => import('~/pages/AuthAcceptInvitation.vue');
const AuthEmailConfirmation = () => import('~/pages/AuthEmailConfirmation.vue');
const AuthForgotPassword = () => import('~/pages/AuthForgotPassword.vue');
const AuthLogin = () => import('~/pages/AuthLogin.vue');
const AuthLoginPleaseWait = () => import('~/pages/AuthLoginPleaseWait.vue');
const AuthNoAccount = () => import('~/pages/AuthNoAccount.vue');
const AuthProfileEdit = () => import('~/pages/AuthProfileEdit.vue');
const AuthRegistration = () => import('~/pages/AuthRegistration.vue');
const AuthResetPassword = () => import('~/pages/AuthResetPassword.vue');
const AuthProfileMyDetails = () => import('~/pages/AuthProfileMyDetails.vue');
const AuthProfileNotifications = () =>
  import('~/pages/AuthProfileNotifications.vue');
const ProfileChangePassword = () => import('~/pages/ProfileChangePassword.vue');

export const authRoutes: Readonly<RouteRecordRaw[]> = [
  makePublicRoute({
    name: 'AzureLogin',
    path: '/auth/callback',
    component: AuthLoginPleaseWait,
    props: (route) => ({
      query: route.query as Record<string, string>,
    }),
  }),
  makePublicRoute({
    name: 'AuthNoAccount',
    path: '/auth/no-account',
    component: AuthNoAccount,
  }),
  makePublicRoute({
    name: 'AuthLogin',
    path: '/login',
    component: AuthLogin,
    props: (route) => ({
      email: route.query.email as string,
    }),
    beforeEnter: async (to, _, next) => {
      if (useProfileStore().isAuthenticated) {
        next((to.query.redirect as string) || '/');
        return;
      }
      next();
    },
  }),
  makePublicRoute({
    name: 'AuthRegistration',
    path: '/registration',
    component: AuthRegistration,
    devFeature: DEV_FEATURE.PUBLIC_REGISTER,
  }),
  makePublicRoute({
    name: 'AuthForgotPassword',
    path: '/forgot-password',
    component: AuthForgotPassword,
  }),
  makePublicRoute({
    name: 'AuthResetPassword',
    path: '/reset-password/:token',
    component: AuthResetPassword,
    props: (route) => ({
      token: route.params.token as string,
    }),
    beforeEnter: async (to, _, next) => {
      const { token } = to.params;

      try {
        await axios.get(`/api/reset-password/${token}`);
        next();
      } catch {
        next('/404');
      }
    },
  }),
  makePublicRoute({
    name: 'AuthAcceptInvitation',
    path: '/invitation/:token',
    component: AuthAcceptInvitation,
    props: (route) => ({
      token: route.params.token as string,
      email: route.params.email as string,
    }),
    beforeEnter: async (to, _, next) => {
      const { token } = to.params as { token: string };
      const path = `/api/invitations/${token}`;

      try {
        const { data } = await axios.get<IAuthInviteResponse>(path);

        const invitation = data.data;

        if (useProfileStore().isAuthenticated) {
          useOrganisationsStore().setById(invitation.organisationId);
          next('/');
          return;
        }

        if (invitation?.expired) {
          next('/expired');
          return;
        }

        if (invitation?.accepted) {
          next('/login');
          return;
        }

        to.params.email = invitation.email;
        next();
        return;
      } catch {
        next('/404');
      }
    },
  }),
  makePublicRoute({
    name: 'AuthEmailConfirmation',
    path: '/confirm-email/:token',
    component: AuthEmailConfirmation,
    props: (route) => ({
      type: route.params.type as IRegistrationBusinessType,
      status: route.params.status as RegistrationStatus,
    }),
    beforeEnter: async (to, _, next) => {
      const { token } = to.params as { token: string };

      return await Service.auth()
        .registration()
        .confirmEmail(token)
        .onSuccess(({ data }: IRegistrationConfirmEmail) => {
          to.params.type = data.type;
          to.params.status = REGISTRATION_STATUS.SUCCESS;
          next();
        })
        .onErrorNotAvailable(() => {
          to.params.status = REGISTRATION_STATUS.EXPIRED;
          next();
        })
        .onErrorConflict(() => {
          to.params.status = REGISTRATION_STATUS.ALREADY_CONFIRMED;
          next();
        })
        .onErrorNotFound(() => next('/404'))
        .onError(() => next('/404'))
        .execute();
    },
  }),
  makePrivateRoute({
    path: '/profile',
    component: AuthProfile,
    props: (route) => ({
      fromList: route.query.from as string,
    }),
    children: [
      {
        path: '',
        name: 'AuthProfile',
        redirect: { name: 'AuthProfileMyDetails' },
      },
      {
        name: 'AuthProfileMyDetails',
        path: 'details',
        component: AuthProfileMyDetails,
        props: true,
      },
      {
        name: 'AuthProfileNotifications',
        path: 'notifications',
        component: AuthProfileNotifications,
        props: true,
      },
      {
        name: 'AuthProfileChangePassword',
        path: 'password',
        component: ProfileChangePassword,
        props: true,
      },
    ],
  }),
  makePrivateRoute({
    name: 'AuthProfileEdit',
    path: '/profile/edit',
    component: AuthProfileEdit,
  }),
];
