<script setup lang="ts" name="ModalForm">
import { watch } from 'vue';
import { useForm } from 'vee-validate';

const { errors } = defineProps<{
  errors?: Record<string, any> | null;
}>();

const emit = defineEmits<{
  (e: 'submit'): void;
}>();

const { validate, setErrors } = useForm();
const onSubmit = async () => {
  const { valid, errors } = await validate();
  if (!valid) {
    setErrors(errors);
    return;
  }

  emit('submit');
};

watch(
  () => errors,
  (errors) => {
    if (errors) setErrors(errors);
  },
);
</script>

<template>
  <form @submit.prevent="onSubmit" class="flex min-h-0 flex-col">
    <slot />
  </form>
</template>
