<script setup lang="ts" name="BasePageLayout">
import { useTemplateRef } from 'vue';

const basePageGrid = useTemplateRef('basePageGrid');

defineExpose({
  grid: basePageGrid,
});
</script>

<template>
  <div
    ref="basePageGrid"
    class="mx-[var(--base-page-grid-margin)] mb-13 grid grid-cols-[var(--base-page-grid)] gap-[var(--base-page-grid-gutter)]"
  >
    <slot />
  </div>
</template>
