<script lang="ts" setup name="BaseCheckbox">
import { computed, useSlots, useTemplateRef, watch } from 'vue';
import * as checkbox from '@zag-js/checkbox';
import { normalizeProps, useMachine } from '@zag-js/vue';
import { v4 as uuidv4 } from 'uuid';

import Icon from '~/components/Icon.vue';

type ValueType = unknown[] | boolean;

const {
  modelValue,
  nativeValue,
  name,
  disabled = false,
  readOnly = false,
  indeterminate = false,
  handleChange,
} = defineProps<{
  modelValue?: ValueType;
  nativeValue?: unknown;
  name?: string | number;
  disabled?: boolean;
  readOnly?: boolean;
  indeterminate?: boolean;
  defaultChecked?: boolean;
  handleChange?: (checkbox: HTMLInputElement) => void;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value?: ValueType): void;
  (e: 'change', event: Event): void;
  (e: 'blur', event: Event): void;
}>();

const slots = useSlots();
const checkboxRef = useTemplateRef<HTMLInputElement>('checkboxRef');

const objectsAreEqual = (a: unknown, b: unknown) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

const getIsChecked = (value?: ValueType): boolean | undefined => {
  if (Array.isArray(value)) {
    return value.some((item) => objectsAreEqual(item, nativeValue));
  }

  return value;
};

const getArrayEmitValue = (checked: boolean, items: unknown[]) => {
  const value = nativeValue || checked === true;

  if (checked === true) {
    return getIsChecked(items) ? items : [...items, value];
  }

  return items.filter((item) => !objectsAreEqual(item, value));
};

const updateInputValue = ({
  checked,
}: {
  checked: boolean | 'indeterminate';
}) => {
  if (checked === 'indeterminate') {
    return;
  }

  const isChecked = checked === true;
  const emitValue: ValueType = Array.isArray(modelValue)
    ? getArrayEmitValue(isChecked, modelValue)
    : isChecked;

  if (!objectsAreEqual(modelValue, emitValue)) {
    emit('update:modelValue', emitValue);
  }
};

const onChange = (event: FocusEvent) => {
  emit('change', event);

  if (handleChange && checkboxRef.value) {
    handleChange(checkboxRef.value);
  }
};

const [state, send] = useMachine(
  checkbox.machine({
    id: uuidv4(),
    name: name?.toString() || '',
    defaultChecked: getIsChecked(modelValue),
    indeterminate: indeterminate,
    readOnly: readOnly,
    disabled: disabled,
    onChange: updateInputValue,
  }),
  {
    context: computed(() => ({
      disabled: disabled,
      indeterminate: indeterminate,
      readOnly: readOnly,
    })),
  },
);

const api = computed(() => checkbox.connect(state.value, send, normalizeProps));

watch(
  () => modelValue,
  (value) => {
    const isChecked = getIsChecked(value);
    if (api.value.isChecked !== isChecked) {
      api.value.setChecked(!!isChecked);
    }
  },
  {
    deep: true,
  },
);
</script>

<template>
  <label
    v-bind="api.rootProps"
    class="relative flex items-center"
    :class="{
      'cursor-not-allowed': api.isDisabled,
      'cursor-pointer': !api.isDisabled,
    }"
  >
    <input
      v-bind="api.inputProps"
      ref="checkboxRef"
      :value="nativeValue"
      @change="onChange"
      @blur="($event) => emit('blur', $event)"
      class="peer left-0 top-0"
    />
    <div
      data-test="checkbox-control"
      v-bind="api.controlProps"
      :class="`-m-[3px] h-6 w-6 rounded-sm text-charcoal-6 transition-colors peer-focus-visible:ring-2 peer-focus-visible:ring-primary-4 data-checked:text-primary-8 data-indeterminate:text-primary-8 data-disabled:text-charcoal-4 data-hover:text-primary-6`"
    >
      <Icon
        v-if="api.isIndeterminate"
        icon="ic:baseline-indeterminate-check-box"
        size="2xl"
      />
      <Icon v-else-if="api.isChecked" icon="ic:baseline-check-box" size="2xl" />
      <Icon v-else icon="ic:baseline-check-box-outline-blank" size="2xl" />
    </div>
    <div
      v-bind="api.labelProps"
      class="flex-1 text-body-1 text-charcoal-9 data-disabled:text-charcoal-4"
      :class="{
        'ml-2': slots.default,
      }"
    >
      <slot />
    </div>
  </label>
</template>
