<script lang="ts" setup name="SailingScheduleTradeLaneHorizontalScroll">
import { ref, useTemplateRef } from 'vue';

import SailingScheduleTradeLaneHorizontalScrollButton from '~/components/SailingScheduleTradeLaneHorizontalScrollButton.vue';

// Compatibility delta due to rounding issues with scrollbar width
const delta = 2.5;

const containerRef = useTemplateRef<HTMLElement>('containerRef');
const forwardVisible = ref(false);
const backVisible = ref(false);
const mouseOver = ref(false);

const onForward = () => {
  if (!containerRef.value) return;

  containerRef.value.scrollTo({
    left: containerRef.value.scrollWidth,
    behavior: 'smooth',
  });
};

const onBack = () => {
  if (!containerRef.value) return;

  containerRef.value.scrollTo({
    left: 0,
    behavior: 'smooth',
  });
};

const updateButtons = () => {
  if (!containerRef.value) return;

  if (containerRef.value.scrollWidth === containerRef.value.clientWidth) return;

  if (containerRef.value.scrollLeft === 0 && mouseOver.value) {
    forwardVisible.value = true;
    return;
  }

  const isMaxScroll =
    containerRef.value.scrollWidth <
    containerRef.value.scrollLeft + containerRef.value.clientWidth + delta;

  if (isMaxScroll && mouseOver.value) {
    backVisible.value = true;
    return;
  }

  forwardVisible.value = false;
  backVisible.value = false;
};

const onMouseEnter = () => {
  mouseOver.value = true;
  updateButtons();
};

const onMouseLeave = () => {
  mouseOver.value = false;
  updateButtons();
};

const onScroll = () => {
  updateButtons();
};
</script>

<template>
  <div class="relative" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <div class="h-full overflow-hidden">
      <div ref="containerRef" class="h-full overflow-auto" @scroll="onScroll">
        <slot />
      </div>
    </div>
    <div class="pointer-events-none absolute inset-0 flex">
      <div v-if="forwardVisible" class="ml-auto w-12 bg-fade" />
      <div v-if="backVisible" class="mr-auto w-12 -rotate-180 bg-fade" />
    </div>
    <div
      class="pointer-events-none absolute inset-y-0 right-0 -mx-6 flex items-center justify-end"
    >
      <SailingScheduleTradeLaneHorizontalScrollButton
        v-if="forwardVisible"
        :forward="true"
        @click="onForward"
      />
      <div class="spacer" />
      <SailingScheduleTradeLaneHorizontalScrollButton
        v-if="backVisible"
        :forward="false"
        @click="onBack"
      />
    </div>
  </div>
</template>
