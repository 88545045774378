import type {
  ICargoBooking,
  ICargoCreateBooking,
  ICargoDeclarationDownloadForm,
  ICargoDeclarationPortSearch,
  ICargoNominatedVessel,
  VesonFormSubmit,
} from '~/types';

import { HTTP } from './HTTP.js';

export class CargoBookingsService {
  organisationId: OrganisationId;
  baseUrl: string;

  constructor(organisationId: OrganisationId) {
    this.organisationId = organisationId;
    this.baseUrl = `/api/customer/organisation/${organisationId}/cargo-bookings`;
  }

  declarations() {
    const path = `${this.baseUrl}/cargo-declarations`;

    return {
      index: () => HTTP.make(path, 'get'),
      filters: () => HTTP.make(`${path}/filters`, 'get'),
      download: (data?: ICargoDeclarationDownloadForm) =>
        HTTP.make(`${path}/download`, 'get').data(data ?? {}),
      import: (file: FormData) =>
        HTTP.make(`${path}/import`, 'post').data(file),
      search: (data: ICargoDeclarationPortSearch) =>
        HTTP.make(`${path}/import/ports`, 'get').data(data),
    };
  }

  nomination() {
    const path = `${this.baseUrl}/cargo-nominations`;

    return {
      download: () => HTTP.make(`${path}/download`, 'get'),
      import: (file: FormData) =>
        HTTP.make(`${path}/import`, 'post').data(file),
      update(vessel: ICargoNominatedVessel) {
        return HTTP.make(`${path}/import/review`, 'put').data({
          ...vessel,
        });
      },
    };
  }

  nominations() {
    const path = `${this.baseUrl}/cargo-nominations`;
    return {
      index: () => HTTP.make(path, 'get'),
      filters: () => HTTP.make(`${path}/filters`, 'get'),
      bookings: () => HTTP.make(`${path}/bookings`, 'get'),
      messages: (vesselId: string) =>
        HTTP.make(`${path}/${vesselId}/messages`, 'get'),
      sendMessage: (vesselId: string, data: { message: string }) =>
        HTTP.make(`${path}/${vesselId}/messages`, 'post').data(data),
      approve: (vesselId: string) =>
        HTTP.make(`${path}/${vesselId}/action/approve`, 'post'),
      confirmUpdates: (vesselId: string) =>
        HTTP.make(`${path}/${vesselId}/action/confirm-updates`, 'post'),
      markAsRead: (vesselId: string, messageId: string) =>
        HTTP.make(
          `${path}/${vesselId}/messages/${messageId}/mark-as-read`,
          'post',
        ),
    };
  }

  transfer(bookingId: string) {
    const path = `${this.baseUrl}/cargo-declarations/${bookingId}/transfer`;

    return {
      index: () => HTTP.make(path, 'get'),
      store: (data: VesonFormSubmit) => HTTP.make(path, 'post').data(data),
    };
  }

  transferErrors() {
    const path = `${this.baseUrl}/errors/transfer`;
    return HTTP.make(path, 'get');
  }

  overview() {
    const path = `${this.baseUrl}/overview`;

    return {
      declaredVolume() {
        const declaredPath = `${path}/declared-previous-volume`;

        return {
          chart: () => HTTP.make(`${declaredPath}/chart`, 'get'),
          table: () => HTTP.make(`${declaredPath}/table`, 'get'),
        };
      },
      nominatedVolume() {
        const nominatedPath = `${path}/declared-nominated-volume`;

        return {
          chart: () => HTTP.make(`${nominatedPath}/chart`, 'get'),
          table: () => HTTP.make(`${nominatedPath}/table`, 'get'),
        };
      },
    };
  }

  history() {
    const path = `${this.baseUrl}/cargo-declarations/history`;

    return {
      index: () => HTTP.make(path, 'get'),
      filters: () => HTTP.make(`${path}/filters`, 'get'),
    };
  }

  updateBooking(booking: ICargoBooking) {
    return HTTP.make(
      `${this.baseUrl}/cargo-declarations/import/review`,
      'put',
    ).data({
      ...booking,
    });
  }

  createBooking(bookingData: ICargoCreateBooking) {
    return HTTP.make(
      `${this.baseUrl}/cargo-declarations/import/review`,
      'post',
    ).data({
      ...bookingData,
    });
  }

  store(importId: string) {
    return HTTP.make(`${this.baseUrl}/cargo-declarations`, 'post').data({
      importId,
    });
  }

  storeVessels(importId: string) {
    return HTTP.make(`${this.baseUrl}/cargo-nominations`, 'post').data({
      importId,
    });
  }
}
