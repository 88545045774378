<script
  setup
  lang="ts"
  name="DataTableOptionsField"
  generic="T extends IOption"
>
import { computed, onMounted, type Ref, ref, useTemplateRef } from 'vue';

import { useCargoFilter } from '~/features/useFieldSelect';
import { debounce } from '~/features/useUtilities';

import FilterMenuOptions from '~/components/FilterMenuOptions.vue';
import FilterRadioInput from '~/components/FilterRadioInput.vue';
import Icon from '~/components/Icon.vue';
import XDropdown from '~/components/XDropdown.vue';

import type { IOption } from '~/types';

const {
  value,
  fieldRef,
  options = [],
  onSearch = null,
} = defineProps<{
  value: T;
  options?: T[];
  fieldRef?: HTMLElement;
  onSearch?: (value: string) => Promise<T[]>;
}>();

const emit = defineEmits<{
  update: [val: any];
  moveRightOrTab: [];
  resetEditMode: [];
  onEnter: [];
}>();
const inputRef = useTemplateRef<HTMLInputElement>('inputRef');
const dropdownRef =
  useTemplateRef<InstanceType<typeof XDropdown>>('dropdownRef');
const isFocused = ref(false);

const optionValue = ref<T | undefined>(value) as Ref<T | undefined>;
const searchOptions = ref<T[] | null>(null) as Ref<T[] | null>;
const allOptions = computed(() => searchOptions.value ?? options);

const labelValue = ref(value.name);

const { filteredOptions } = useCargoFilter(optionValue, labelValue, allOptions);

const onSelect = () => {
  inputRef.value?.blur();
  dropdownRef.value?.close();
  emit('update', optionValue.value);
  emit('onEnter');
};

const handlePODValidation = () => {
  if (!labelValue.value) {
    emit('update', {
      id: null,
      name: null,
    });
  }
};

const onKeyDown = (event: KeyboardEvent) => {
  event.stopPropagation();

  if (event.key === 'Tab') {
    emit('moveRightOrTab');
    event.preventDefault();
  } else if (event.key === 'Enter') {
    handlePODValidation();
    emit('onEnter');
    event.preventDefault();
  } else if (event.key === 'Escape') {
    emit('resetEditMode');
  }
};

const onInput = () => {
  if (onSearch && labelValue.value.length > 1) {
    debounce(async () => {
      searchOptions.value = await onSearch(labelValue.value);
    })();
  }
};

const handleClickOutside = () => {
  handlePODValidation();
  emit('resetEditMode');
};

onMounted(() => {
  inputRef.value?.focus();
  inputRef.value?.select();
  dropdownRef.value?.open();
});
</script>

<template>
  <XDropdown
    ref="dropdownRef"
    :parentRef="fieldRef"
    @clickOutside="handleClickOutside"
    @dblclick.stop
  >
    <template #trigger="{ isOpen }">
      <input
        v-model="labelValue"
        class="w-full max-w-full truncate focus:outline-none"
        ref="inputRef"
        @keydown="onKeyDown"
        @input="onInput"
        :placeholder="onSearch ? 'Start typing' : ''"
      />

      <Icon
        v-if="isFocused"
        icon="mdi:chevron-right"
        class="pointer-events-none transform transition-transform ease-in"
        :class="{
          '-rotate-90': isOpen,
          'rotate-90': !isOpen,
        }"
      />
    </template>

    <template #default>
      <FilterMenuOptions :has-options="filteredOptions.length > 0">
        <FilterRadioInput
          v-for="option in filteredOptions"
          :key="option.id"
          v-bind="option"
          v-model="optionValue"
          :native-value="option"
          :active="optionValue?.id === option.id"
          @select="onSelect"
        >
          {{ option.name }}
        </FilterRadioInput>
      </FilterMenuOptions>
    </template>
  </XDropdown>
</template>
