<script setup lang="ts" name="DataTableCellField">
import { computed } from 'vue';

import DataTableDatePicker from '~/components/DataTableDatePicker.vue';
import DataTableLink from '~/components/DataTableLink.vue';
import DataTableNumericField from '~/components/DataTableNumericField.vue';
import DataTableOptionsField from '~/components/DataTableOptionsField.vue';
import DataTableTextAreaField from '~/components/DataTableTextAreaField.vue';

import type {
  DataTableSettingsOptions,
  IDataTableCell,
  IDataTableCellValue,
} from '~/types';

const { cell, fieldRef, options } = defineProps<{
  cell: IDataTableCell;
  fieldRef?: HTMLElement;
  options?: DataTableSettingsOptions;
}>();

const emit = defineEmits<{
  update: [value: IDataTableCellValue];
  moveRightOrTab: [];
  resetEditMode: [];
  onEnter: [];
}>();

const componentLookup = {
  date: DataTableDatePicker,
  numeric: DataTableNumericField,
  textArea: DataTableTextAreaField,
  options: DataTableOptionsField,
};

const propsLookup = computed(() => {
  const key = cell.key.split('.')[0];

  return {
    options: {
      fieldRef: fieldRef,
      options: cell.options ?? options?.[key],
      onSearch: cell.onSearch,
    },
  };
});

const fieldValue = computed(() => (value: IDataTableCellValue) => {
  if (!value) return cell.fallback;

  if (cell.valueFormatter) return cell.valueFormatter(value);

  return value;
});
</script>

<template>
  <component
    v-if="cell.edit"
    :value="cell.value"
    :is="componentLookup[cell.type]"
    v-bind="propsLookup[cell.type]"
    @update="emit('update', $event)"
    @reset-edit-mode="emit('resetEditMode')"
    @move-right-or-tab="emit('moveRightOrTab')"
    @on-enter="emit('onEnter')"
  />

  <template v-else>
    <template v-if="cell.type === 'options'">
      <p v-title class="truncate">{{ fieldValue(cell.value?.name) }}</p>
    </template>

    <template v-else-if="cell.link">
      <DataTableLink
        :value="fieldValue(cell.value) as string"
        :link="cell.link"
      />
    </template>

    <template v-else>
      <p v-title class="truncate">{{ fieldValue(cell.value) }}</p>
    </template>
  </template>
</template>
