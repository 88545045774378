import { computed, isRef, reactive, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { STATES } from '~/constants';

import { useAutoPagination } from '~/features/useAutoPagination';
import {
  debounce,
  isEmpty,
  removeNullProperties,
} from '~/features/useUtilities';

/**
 * This callback type is called `successCallback` and is displayed as a global symbol.
 *
 * @callback successCallback
 * @param {T} response - The response from the service.
 *
 * @returns {boolean} - Returns true if the response is has data, otherwise false.
 */

/**
 * @typedef {"IDLE"|"ERROR"|"LOADING"|"NO_DATA"|"NOT_AUTHORIZED"|"NO_RESULTS"|"NOT_AVAILABLE"} ServiceState
 */

const defaultOptions = {
  state: STATES.LOADING,
  preventFetch: false,
  debounceTime: 350,
  cancelOnNewRequest: false,
  paginated: false,
  paginationOptions: [10, 15, 30, 60],
  customInitialParams: false,
  query: null,
  preventWatch: () => false,
};

const getUrlQuery = (options) =>
  typeof options.getRouteQuery !== 'undefined'
    ? options.getRouteQuery()
    : options.getQuery();

/**
 * @param {import('~/services/HTTP.js').HTTP} service
 * @param {successCallback} onSuccess
 * @param {Object | undefined} userOptions
 * @param {boolean | undefined} userOptions.preventFetch - If true, the state will not be set to LOADING.
 * @param {ServiceState | undefined} userOptions.state - The initial state of the service.
 * @param {number | undefined} userOptions.debounceTime - The timeout used for debounce, default is 200.
 * @param {boolean | undefined} userOptions.cancelOnNewRequest - If true, will cancel previous request on new fetch.
 * @param {boolean | undefined} userOptions.paginated - If true, will add pagination
 * @param {number[] | undefined} userOptions.paginationOptions - the pagination options
 * @param {boolean | undefined} userOptions.customInitialParams - If true, will not set initial params
 * @param {Object | undefined} userOptions.query - if provided, will watch the query and refetch data on change
 * @param {Function | undefined} userOptions.getQuery - will be called to get the query for API url
 * @param {Function | undefined} userOptions.getRouteQuery - will override the default query that was provided and set the custom URL query
 * @param {Function | undefined} userOptions.preventWatch - null or a function that will return true if the fetch should be prevented
 * @param {Function | undefined} userOptions.onErrorNotFoundRedirection - if provided, will redirect to the provided url if the error is 404
 * @param {spinner | skeleton} userOptions.loading - default is set to `spinner`
 * @param {Function | undefined} userOptions.onNoData - If provided, will call the function whenever the HTTP response is 204
 *  */

// TODO: Investigate the usage of useService. Right now the actual useService presets query to the URL, but preseting URL query manually, not
// using vue router, changing the query makes going back and forward not re-rendering the page/content. Should useService receive router instance as a params,
// and additional logic added to useService track down router query changes and re-render the page/content?

// If you are pushing a NEW route with not a full route query thats going to be presented, this creates an empty route instance without the full query,
// changing the query and then navigating back, returns back to the empty route instance without any of route query. This way the user is not returned where he was,
// but to the empty route instance, because it did not had full route query to begin with.

export const useService = (service, onSuccess, userOptions = {}) => {
  const router = useRouter();
  const route = useRoute();
  const options = {
    ...defaultOptions,
    ...userOptions,
  };

  const initialParams = new URLSearchParams(window.location.search);
  const requestCancelToken = ref(null);
  const state = ref(userOptions.state || options.state);

  const { withPagination, updatePagination, resetPage } = useAutoPagination(
    () => {
      fetchData();

      updateQueryParams();
    },
    {
      options: options.paginationOptions,
      page: initialParams.get('page'),
      perPage: initialParams.get('per_page'),
    },
  );

  const setState = (value) => {
    state.value = value;
  };

  const setStateLoading = () => {
    setState(STATES.LOADING);
  };

  const setStateIdle = () => {
    setState(STATES.IDLE);
  };

  const setStateError = () => {
    setState(STATES.ERROR);
  };

  const setStateNoData = () => {
    setState(STATES.NO_DATA);
  };

  const setStateErrorNotAvailable = () => {
    setState(STATES.NOT_AVAILABLE);
  };

  const setStateNotAuthorized = () => {
    setState(STATES.NOT_AUTHORIZED);
  };

  const setStateNoResults = () => {
    setState(STATES.NO_RESULTS);
  };

  const setStateValidationError = () => {
    setState(STATES.VALIDATION_ERROR);
  };

  const addQuery = (serviceRef) => {
    let data = null;

    if (!isEmpty(options.query)) {
      data = {
        ...options.getQuery(),
      };
    }

    if (options.paginated) {
      const { page, perPage } = withPagination();
      data = {
        ...(data || {}),
        page,
        per_page: perPage,
      };
    }

    return data ? serviceRef.data(removeNullProperties(data)) : serviceRef;
  };

  const fetchData = () => {
    let serviceValue = isRef(service) ? service.value : service;
    serviceValue
      .onStart(setStateLoading)
      .onError(setStateError)
      .onNoData(() => {
        setStateNoData();

        if (options.onNoData) {
          options.onNoData();
        }
      })
      .onErrorAuth(() => {
        router.push('/403');

        setStateNotAuthorized();
      })
      .onErrorNotAvailable(setStateErrorNotAvailable)
      .onErrorValidation(setStateValidationError)
      .onErrorNotFound(() => {
        userOptions.onErrorNotFoundRedirection
          ? userOptions.onErrorNotFoundRedirection()
          : setStateNoData();
      })
      .onSuccess(async (response) => {
        if (options.paginated) {
          updatePagination(response);
        }

        if (await onSuccess(response)) {
          setStateIdle();
        } else {
          setStateNoResults();
        }
      });

    if (options.cancelOnNewRequest) {
      serviceValue = serviceValue.cancelOnNewRequest(requestCancelToken);
    }

    serviceValue = addQuery(serviceValue);

    serviceValue.execute();
  };

  const updateQueryParams = () => {
    const url = new URL(window.location);
    const { hash } = url;
    url.search = '';
    if (!isEmpty(options.query)) {
      const query = removeNullProperties(getUrlQuery(options));
      Object.keys(query).forEach((key) => {
        url.searchParams.set(key, query[key]);
      });
    }

    if (options.paginated) {
      const { page, perPage } = withPagination();
      url.searchParams.set('page', page);

      url.searchParams.set('per_page', perPage);
    }

    const { pathname, search } = url;
    const newPath = `${pathname}${search}${hash}`;
    const currentPath = route.fullPath;
    if (newPath !== currentPath) {
      router.replace(newPath);
    }
  };

  const fetchDataDebounced = debounce(() => {
    fetchData();
    updateQueryParams();
  }, options.debounceTime);

  if (options.preventFetch === false) {
    fetchData();
  }

  if (!isEmpty(options.query)) {
    watch(
      () => options.query,
      (value, oldValue) => {
        if (options.preventWatch(value, oldValue)) {
          return;
        }

        if (options.paginated) {
          resetPage();
        }

        fetchDataDebounced();
      },
      { deep: true },
    );
  }
  if (options.immediate) {
    updateQueryParams();
  }

  return reactive({
    state,
    loading: options.loading ?? 'spinner',
    fetchDataDebounced,
    fetchData,
    setStateNotAuthorized,
    setStateNoResults,
    setStateLoading,
    setStateNoData,
    setStateError,
    setStateIdle,
    stateIsLoading: computed(() => state.value === STATES.LOADING),
    stateIsError: computed(() => state.value === STATES.ERROR),
    stateIsNotAuthorized: computed(() => state.value === STATES.NOT_AUTHORIZED),
    stateIsNoResults: computed(() => state.value === STATES.NO_RESULTS),
    stateIsNoData: computed(() => state.value === STATES.NO_DATA),
    stateIsIdle: computed(() => state.value === STATES.IDLE),
    stateIsNotAvailable: computed(() => state.value === STATES.NOT_AVAILABLE),
    stateIsValidationError: computed(
      () => state.value === STATES.VALIDATION_ERROR,
    ),
    stateHasErrors: computed(
      () =>
        state.value === STATES.ERROR ||
        state.value === STATES.NOT_AUTHORIZED ||
        state.value === STATES.NO_DATA,
    ),
  });
};
