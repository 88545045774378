<script lang="ts" setup name="SailingScheduleTradeLanePort">
import { computed } from 'vue';

import VoyageTracker from '~/components/VoyageTracker.vue';

const {
  id,
  name,
  first = false,
  last = false,
  hover = false,
  visible = false,
  hasSelectedPorts = false,
  fullScreen = false,
  sorted = false,
} = defineProps<{
  id: string;
  portDisabled?: boolean;
  name: string;
  first?: boolean;
  last?: boolean;
  hover?: boolean;
  visible?: boolean;
  hasSelectedPorts?: boolean;
  fullScreen?: boolean;
  sorted?: boolean;
}>();

const emit = defineEmits<{
  (e: 'mouseover', id: string): void;
  (e: 'mouseout', id: string): void;
}>();

const isPortActive = computed(() => hasSelectedPorts && visible && fullScreen);

const shouldHaveBackground = computed(() => {
  if (isPortActive.value) return true;

  return hover;
});
</script>

<template>
  <div
    class="relative h-14 overflow-hidden border-r border-charcoal-2"
    data-testid="sailing-schedule-trade-lane-port"
    :class="{
      'bg-charcoal-1': shouldHaveBackground,
      'font-bold': isPortActive,
    }"
    @mouseover="emit('mouseover', id)"
    @mouseout="emit('mouseout', id)"
  >
    <div
      v-if="(hover && !first && !sorted) || isPortActive"
      class="absolute inset-x-0 top-0 h-[1px] bg-charcoal-2"
    />
    <div
      v-if="(hover && !sorted) || isPortActive"
      class="absolute inset-x-0 bottom-0 h-[1px] bg-charcoal-2"
    />
    <div class="px-4">
      <VoyageTracker
        padding="large"
        :hideTopBorder="first"
        :hideBottomBorder="last"
        :disabled="portDisabled"
        :port="{ name }"
      />
    </div>
  </div>
</template>
