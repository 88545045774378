import { getDateYear } from '~/features/useDates';

import type {
  CargoBookingPort,
  ICargoBooking,
  ICargoBookingRow,
} from '~/types';

interface UpdateOptions {
  booking: ICargoBookingRow;
  importId: string | null;
}

interface CreateOptions {
  booking: ICargoBooking;
}

const formatNumber = (number: null | string | number) => {
  if (number === null || number === '') {
    return null;
  }

  return Number(number);
};

export const formatDate = (date: null | string) => {
  if (date === null || date === '') {
    return null;
  }

  return getDateYear(date);
};

const formatPort = (port: CargoBookingPort) => ({
  ...port,
  qty: formatNumber(port.qty),
});

export const formatLaycanStart = (date: string | null, id?: string | null) =>
  id ? 'Continuation' : date;

export const formatLaycanFinish = (date: string | null, id?: string | null) =>
  id ? null : date;

const formatBookingPayload = (
  booking: ICargoBookingRow | ICargoBooking,
  updates?,
) => ({
  ...booking,
  type: booking.type?.id,
  loadingPort: {
    ...booking.loadingPort,
    qty: formatNumber(booking.loadingPort.qty),
  },
  vesselVoyage: booking.vesselVoyage,
  trade: booking.trade?.id,
  month: booking.month?.id,
  status: booking.status?.id,
  laycanFinish: formatDate(booking.laycanFinish),
  laycanStart: formatDate(booking.laycanStart),
  comment: booking.comment || null,
  ...updates,
});

const payloadFormatter = ({
  booking,
  properties,
  portsFormatter,
}: {
  booking: ICargoBooking;
  properties: object;
  portsFormatter: (ports: CargoBookingPort[]) => CargoBookingPort[];
}): any =>
  formatBookingPayload(booking, {
    dischargePorts: portsFormatter(booking.dischargePorts).map((port) =>
      formatPort(port),
    ),
    ...properties,
  });

const getInvalidPortIndices = (ports: CargoBookingPort[]) => {
  let lastInvalidPortPosition = -1;
  let firstInvalidPortPosition = -1;

  ports.forEach((port, index) => {
    if ((port.id !== null && port.name !== null) || port.qty !== null) {
      lastInvalidPortPosition = index;
    } else if (firstInvalidPortPosition === -1) {
      firstInvalidPortPosition = index;
    }
  });

  return { firstInvalidPortPosition, lastInvalidPortPosition };
};

const filterValidOrEditedPorts = (ports: CargoBookingPort[]) =>
  ports.filter(
    (port) =>
      port.edited ||
      (port.id !== null && port.name !== null) ||
      port.qty !== null,
  );

const filterEditedPorts = (ports: CargoBookingPort[]): CargoBookingPort[] =>
  ports.filter((port) => port.edited);

export const getBookingUpdatePayload = ({
  booking,
  importId,
}: UpdateOptions): ICargoBookingRow[] => {
  const { firstInvalidPortPosition, lastInvalidPortPosition } =
    getInvalidPortIndices(booking.dischargePorts);

  const portsFormatter =
    firstInvalidPortPosition > lastInvalidPortPosition
      ? filterValidOrEditedPorts
      : (ports: CargoBookingPort[]) => ports;

  return payloadFormatter({
    booking,
    portsFormatter,
    properties: { importId },
  });
};

export const getBookingCreatePayload = ({
  booking,
}: CreateOptions): ICargoBooking => {
  return payloadFormatter({
    booking,
    portsFormatter: filterEditedPorts,
    properties: {},
  });
};
