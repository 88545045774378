<script setup name="OTransitionExpand">
import { Transition, TransitionGroup } from 'vue';

defineProps({
  grouped: {
    type: Boolean,
    default: false,
  },
});

const onAfterEnter = (element) => {
  element.style.height = 'auto';
};
const onEnter = (element) => {
  const { width } = getComputedStyle(element);
  element.style.width = width;
  element.style.visibility = 'hidden';
  element.style.height = 'auto';
  const { height } = getComputedStyle(element);
  element.style.width = null;
  element.style.visibility = null;
  element.style.height = 0;
  // Force repaint to make sure the
  // animation is triggered correctly.
  setTimeout(() => {
    element.style.height = height;
  });
};

const onLeave = (element) => {
  const { height } = getComputedStyle(element);
  element.style.height = height;
  // Force repaint to make sure the
  // animation is triggered correctly.
  setTimeout(() => {
    element.style.height = 0;
  });
};
</script>

<template>
  <TransitionGroup
    v-if="grouped"
    name="g2o-transition-expand"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @leave="onLeave"
  >
    <slot />
  </TransitionGroup>
  <Transition
    v-else
    name="g2o-transition-expand"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @leave="onLeave"
  >
    <slot />
  </Transition>
</template>

<style scoped>
* {
  will-change: height;
}

.g2o-transition-expand-enter-active,
.g2o-transition-expand-leave-active {
  transition: height 0.1s ease-in-out;
  overflow: hidden;
}

.g2o-transition-expand-enter-from,
.g2o-transition-expand-leave-to {
  height: 0;
}
</style>
